import quizzes from "./quizzes.json";

export interface Question {
	answers: (string | string[] | null)[];
	alert: Record<string, string>;
	improvement: Record<string, string>;
	positivePoint: Record<string, string>;
	reason: Record<string, string>;
	tip: Record<string, string>;
	title: string;
}

export interface Category {
	title: string;
	description: string;
	url: string;
	questions: Question[];
	explanation: string;
	conclusion: string[];
}

export const quiz = quizzes[process.env.NEXT_PUBLIC_QUIZ_KIND as keyof typeof quizzes];

export const questions = quiz.categories.reduce<Record<string, Question[]>>((obj, { title, questions }) => {
	obj[title] = questions as unknown as Question[];

	return obj;
}, {});

export const maxPoints = Object.fromEntries(
	Object.entries(questions).map(([category, questions]) => [
		category,
		questions.reduce((sum, { answers }) => sum + answers.length - 1, 0),
	]),
);

export interface Answers {
	[question: string]: {
		[answer: string]: number;
	};
}

export const answers: Answers = {};

for (const { answers: questionAnswers, title: question } of Object.values(questions).flat()) {
	answers[question] = {};

	for (const [i, answer] of questionAnswers.entries()) {
		if (!answer) {
			continue;
		}

		if (Array.isArray(answer)) {
			for (const answer2 of answer) {
				answers[question][answer2] = i;
			}

			continue;
		}

		answers[question][answer] = i;
	}
}

export type CategoryData = Omit<Category, "title" | "questions">;

export const categories = Object.keys(questions);
export const categoryByUrl = new Map<string, string>(quiz.categories.map(({ title, url }) => [url, title]));
export const categoryData = new Map<string, CategoryData>(
	quiz.categories.map(({ title, questions, ...data }) => [title, data as CategoryData]),
);

export const findNextStep = (currentCategory: string): string => {
	const categoryEntries = [...categoryData.entries()];

	let url = categoryEntries[0][1].url;

	for (let i = 1; i < categoryData.size; i++) {
		const [previousCategory] = categoryEntries[i - 1];

		if (previousCategory === currentCategory) {
			url = categoryEntries[i][1].url;
			break;
		}
	}

	return url;
};

export const findPreviousStep = (currentCategory: string): string => {
	const categoryEntries = [...categoryData.entries()];

	let url = "";

	for (let i = 0; i < categoryData.size - 1; i++) {
		const [previousCategory] = categoryEntries[i + 1];

		if (previousCategory === currentCategory) {
			url = categoryEntries[i][1].url;
			break;
		}
	}

	return url;
};
